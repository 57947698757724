<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
      @show-modal="clear(obj)"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseSearchCondition
            :searchCondition="searchCondition"
            :searchConditionInfo="searchConditionInfo"
            @clear="clear(obj)"
            @search="search(obj)"
          >
          </BaseSearchCondition>
          <br />
          <!-- テーブル -->
          <BasePagingTable
            v-model="searchCondition.size"
            id="work-list-table"
            select-mode="single"
            :fields="fields"
            :displayModals="displayModals"
            :items.sync="tableItems"
            :selected-items.sync="selectedItem"
            :columnInfoPage="pagingInfo.page"
            :columnInfoSize="pagingInfo.size"
            :optionsPage="getListOptions(MASTER_CODE.LIST_PAGE, false)"
            :optionsSize="getListOptions(MASTER_CODE.LIST_SIZE, false)"
            :perPage="perPage"
            @size-changed="updateSize"
          />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 選択ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.select"
          :disabled="!oneSelected"
          @click="select"
        />
        <!-- 選択解除ボタン -->
        <BaseButton
          v-if="useUnset"
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.unselect"
          @click="unselect"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  getCountPerReferencePage,
  openSubModal,
} from '@/common/Common.js'
import { search, clear } from '@/master/freightItem/FreightItemModal.js'
import { DELETED_KIND } from '@/common/const.js'

export default {
  name: 'freightItemModal',

  components: {},

  data() {
    return {
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItem: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],

      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      perPage: getCountPerReferencePage(),
      obj: this,
    }
  },

  props: {
    selectedSearchCondition: {
      type: Object,
      default: () => {},
    },
    displayModals: {
      type: String,
      default: 'one',
    },
    useUnset: {
      type: Boolean,
      default: true,
    },
    fixedPayment: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    selectedSearchCondition() {
      // 支払
      this.searchCondition.costType = this.selectedSearchCondition.costType
      // 部署グループ
      this.searchCondition.departmentGroup = this.selectedSearchCondition.departmentGroup
    },
  },

  computed: {
    pagingInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'paging'
      )
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.FREIGHT_ITEM_MODAL_LIST
      )('search_condition')
      // 請求支払区分 変更不可設定
      if (this.fixedPayment == true) {
        searchConditionInfo.cost_type.visibility = this.VISIBILITY.DISABLE
      }
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        page: searchConditionInfo.page,
        size: searchConditionInfo.size,
        inputComponents: [
          {
            // 運賃項目名
            type: 'text',
            id: 'freightItemName',
            columnInfo: searchConditionInfo.freight_item_name,
            maxLength: 50,
          },
          {
            // 部署グループ
            type: 'text',
            id: 'departmentGroup',
            columnInfo: searchConditionInfo.department_group,
            maxLength: 2,
            rules: [this.INPUT_TYPE_CHECK.ONLY_ALPHANUMERIC],
          },
          {
            // 請求支払区分
            type: 'select',
            id: 'costType',
            columnInfo: searchConditionInfo.cost_type,
            options: getListOptions(this.MASTER_CODE.COST_TYPE),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.FREIGHT_ITEM_MODAL_LIST
      )('result_ctrl_info')
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.FREIGHT_ITEM_MODAL_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length === 1
    },
  },

  methods: {
    getListOptions,
    search,
    clear,
    openSubModal,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        freightItemName: null,
        costType: null,
        departmentGroup: null, //部署グループ
        deleted: DELETED_KIND.NOT_DELETED,
        size: getSearchMaxCount(),
        screenId: this.SCREEN_ID.FREIGHT_ITEM_MODAL_LIST,
        domainName: 'result_fields',
      }
    },

    select() {
      if (this.displayModals != 'two') {
        this.$bvModal.hide('freight-item-modal')
      } else {
        this.$bvModal.hide('freight-item-modal-edit')
      }
      this.$emit(
        'after-close-set',
        this.selectedItem[0]
        //this.selectedItem[0].freight_item_id,
        //this.selectedItem[0].freight_item_name
        //this.selectedItem[0].cost_type
        //this.selectedItem[0].cost_type_name
        //this.selectedItem[0].interface_key
        //this.selectedItem[0].work_type
        //this.selectedItem[0].sales_type
        //this.selectedItem[0].text_type
        //this.selectedItem[0].block_code
        //this.selectedItem[0].block_name
      )
    },

    unselect() {
      if (this.displayModals != 'two') {
        this.$bvModal.hide('freight-item-modal')
      } else {
        this.$bvModal.hide('freight-item-modal-edit')
      }
      this.$emit('after-close-unset')
    },

    updateSize: function (size) {
      this.searchCondition.size = size
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
